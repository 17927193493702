import React, { useState, useEffect } from 'react';

import { CenterFeatures } from '../WhyRouteManager/CenterFeatures';
import { WaveUpSVG } from '../WaveSVGs/WaveUpSVG';
import { WaveDownSVG } from '../WaveSVGs/WaveDownSVG';

export const DemoVideoBody = ({ centerSection }) => {
	const [selected, setSelected] = useState(null);

	useEffect(() => {
		setSelected(centerSection.content[0]);
	}, []);

	return (
		<>
			<WaveDownSVG fill='#FFF' shadow />
			<div style={{ background: '#f5f9ff', padding: '16rem 0' }}>
				<CenterFeatures
					centerSection={centerSection}
					setSelected={setSelected}
					selected={selected}
				/>
			</div>
			<WaveUpSVG fill='#FFF' shadow />
		</>
	);
};
