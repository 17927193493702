import React, { useRef, useContext, lazy, Suspense } from 'react';
import { graphql } from 'gatsby';
import loadable from '@loadable/component';

import { Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { Resources } from '../components/General/Resources';
import { WaveUpSVG } from '../components/WaveSVGs/WaveUpSVG';
import { WaveDownSVG } from '../components/WaveSVGs/WaveDownSVG';
import FormModalContext from '../context/FormModalContext';
import { SEO } from '../components/SEO';
import { CustomerLogos } from '../components/CustomerLogos';
import { DemoVideoBody } from '../components/DemoVideo/DemoVideoBody';
import { Testimonials } from '../components/Testimonials';
import { Hero } from '../components/General/Hero';
import { HeroVideo } from '../components/General/HeroVideo';

// import { Form } from '../components/ContactForm/Form';

const Form = loadable(() => import('../components/ContactForm/Form'));

const useStyles = makeStyles((theme) => ({
	formBackground: {
		background: theme.workwaveBlue,
		paddingBottom: '2rem',
		marginTop: '-7rem',
		[theme.breakpoints.down('md')]: {
			marginTop: '-9rem',
		},
		[theme.breakpoints.down('sm')]: {
			marginTop: '-10rem',
		},
		[theme.breakpoints.down('xs')]: {
			marginTop: '-12rem',
		},
	},
	heroVideoCont: {
		// marginBottom: '2rem',
		[theme.breakpoints.down('md')]: {
			margin: '2rem 0',
		},
	},
	formWaveDown: {
		marginBottom: '-8rem',
		[theme.breakpoints.down('sm')]: {
			marginBottom: '-4rem',
		},
		[theme.breakpoints.down('xs')]: {
			marginBottom: '-3rem',
		},
	},
	formWaveUp: {
		marginBottom: '-8rem',
		[theme.breakpoints.down('sm')]: {
			marginBottom: '-10rem',
		},
	},
	formCont: {
		padding: '24rem 0',
		[theme.breakpoints.down('md')]: {
			padding: '16rem 0',
		},
		[theme.breakpoints.down('xs')]: {
			padding: '8rem 0',
		},
	},
}));

const DemoVideoPage = ({ data, location }) => {
	const demoVideo = data.demoVideo.edges[0].node;
	const classes = useStyles();
	const inputRef = useRef();
	const formRef = useRef(null);

	//stuff for modal and form
	const { formModalOpen, handleModalClick, setFormModalOpen, email } =
		useContext(FormModalContext);

	const handleInputScroll = (e) => {
		e.preventDefault();
		inputRef?.current?.scrollIntoView({
			behavior: 'smooth',
		});
	};

	const med = useMediaQuery('(max-width: 960px)');
	const {
		metaTitle,
		metaDescription,
		hero,
		thumbnailImage,
		wistiaLink,
		customerLogos,
		customerLogosHeader,
		formBgImage,
		marketoId,
		pardotUrl,
		contactForm,
		resourceTitle,
		resources,
		_rawResourceBody,
		testimonial,
		testimonialTitle,
		heroImage,
		centerSection,
	} = demoVideo;
	//Think I want to clean and refactor this into smaller components for readability.

	return (
		<>
			<SEO title={metaTitle} description={metaDescription} noIndex />
			<Hero
				hero={hero}
				handleInputScroll={handleInputScroll}
				waveHeight='150'
			/>
			<Container className={classes.heroVideoCont}>
				<HeroVideo thumbnailImage={thumbnailImage} wistiaLink={wistiaLink} />
			</Container>
			<DemoVideoBody centerSection={centerSection} />
			<div
				style={{
					background: 'transparent',
					// padding: '2rem 0',
				}}>
				<Testimonials
					header={testimonialTitle}
					testimonials={testimonial}
					formRef={formRef}
				/>
			</div>
			<Container>
				<CustomerLogos
					customerLogosArray={customerLogos}
					customerLogosHeader={customerLogosHeader}
				/>
			</Container>
			<WaveDownSVG fill='#FFFFFF' />

			<div
				style={{
					backgroundImage: `url(${formBgImage?.asset?.gatsbyImageData?.images.fallback.src})`,
					backgroundSize: 'cover',
				}}
				className={classes.formCont}>
				<Form
					formId={marketoId}
					pardotUrl={pardotUrl}
					contactForm={contactForm}
					privacy
					modal={false}
					location={location}
				/>
			</div>
			<WaveUpSVG fill='#FFFFFF' />
			<Container>
				<Resources
					header={resourceTitle}
					resources={resources}
					subheader={_rawResourceBody}
				/>
			</Container>
			<WaveUpSVG fill='#f4f8ff' />
		</>
	);
};

export const query = graphql`
	query DemoVideoPageQuery {
		demoVideo: allSanityDemoVideo {
			edges {
				node {
					title
					metaTitle
					metaDescription
					hero {
						backgroundImage {
							asset {
								gatsbyImageData(placeholder: BLURRED)
							}
						}
						_rawContent
					}
					heroImage {
						asset {
							gatsbyImageData(placeholder: BLURRED)
						}
					}
					thumbnailImage {
						asset {
							gatsbyImageData
						}
					}
					wistiaLink
					centerSection {
						_key
						header
						subheader
						content {
							_key
							title
							icon
							header
							subheader
							image {
								asset {
									gatsbyImageData(placeholder: BLURRED)
								}
							}
							ctaText
							ctaLink
							internalLink
							_rawFeatureContent
						}
					}
					testimonialTitle
					testimonial {
						title
						header
						testimonialText
						nameAndTitle
						company
						image {
							asset {
								gatsbyImageData(placeholder: BLURRED)
							}
						}
					}
					customerLogosHeader
					customerLogos {
						title
						logo {
							asset {
								gatsbyImageData(placeholder: BLURRED)
							}
						}
					}
					formBgImage {
						asset {
							gatsbyImageData(placeholder: BLURRED)
						}
					}
					contactForm {
						header
						privacyPolicy
					}
					marketoId
					pardotUrl
					resources {
						title
						image {
							asset {
								gatsbyImageData(placeholder: BLURRED)
							}
						}
						blurb
						ctaLink
						ctaText
					}
					resourceTitle
					_rawResourceBody
				}
			}
		}
	}
`;

export default DemoVideoPage;
